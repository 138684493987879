
import { Controller } from "stimulus"

const byte_limit = 10_485_760 // TODO: make this configurable

export default class extends Controller {
    static targets = [ 'submitBtn' ];

    validateFile(event) {
        if (event.target.files[0].size > byte_limit)
            this.rejectFile(event.target)
        else
            this.acceptFile()
    }

    // "private" functions

    acceptFile(){
        if (this.hasSubmitBtnTarget)
          this.submitBtnTarget.removeAttribute('disabled')
    }

    rejectFile(inputElement){
        inputElement.value = ''
        alert('This file exceeds the maximum upload size of 10MB. Please select a smaller file.')
    }
}
