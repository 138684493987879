
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [];

    connect() {
        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })
    }
}
