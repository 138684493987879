
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form', 'wrapper']

  connect() {
    if (this.inProgress()) { this.fetchProgressAfter(5000) }
  }

  createReport(event) {
    event.stopPropagation();
    event.preventDefault();

    if (this.data.get('submitted')) {return} // prevents double-submission
    this.data.set('submitted', true)

    const formData = new FormData(this.formTarget);
    fetch(this.formTarget.action, { method: 'POST', body: formData })
      .then(response => response.json())
      .then(data => {
        if (data['partial']) {
          this.data.set('id', data['id'])
          this.updateView(data['partial']);
        }
      })
    this.fetchProgressAfter(5000)
  }

  fetchProgress() {
    if (this.data.get('id') == '') {return}

    fetch(this.progressUrl())
      .then(response => response.json())
      .then(data => {
        if (data['partial']) {
          this.updateView(data['partial']);
        }
        if (data['in_progress'] === true) {
          this.fetchProgressAfter(5000)
        }
      })
  }

  fetchProgressAfter(milliseconds = 5000) {
    const controller = this;
    setTimeout(function(){controller.fetchProgress()}, milliseconds)
  }

  inProgress() {
    return this.data.get('inProgress') === 'true'
  }

  progressUrl() {
    return this.data.get('url') + '/' + this.data.get('id') + '/progress'
  }

  updateView(html) {
    this.wrapperTarget.innerHTML = html
    $('[data-toggle="tooltip"]').tooltip();
  }
}
