
import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if (!window.localStorage.getItem('puentes_dev_alert_viewed')) {
      this.playAnimation()
    }
  }

  /* Private */

  playAnimation() {
    this.element.classList.add('hide')

    setTimeout(() => {
      this.element.classList.add('showing')
      window.requestAnimationFrame(() => { this.element.classList.remove('hide') })
      window.localStorage.setItem('puentes_dev_alert_viewed', true)
    }, 13)
  }
}
