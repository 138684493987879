
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'cardBody', 'show', 'hide' ]

  toggle() {
    if (this.is_hidden()) {
      this.showBody();
    } else { this.hideBody(); }
  }

  showBody() {
    this.cardBodyTarget.classList.remove('collapse');
    this.showTarget.classList.add('hidden');
    this.hideTarget.classList.remove('hidden');
  }

  hideBody() {
    this.cardBodyTarget.classList.add('collapse')
    this.hideTarget.classList.add('hidden');
    this.showTarget.classList.remove('hidden');
  }

  is_hidden() {
    return this.cardBodyTarget.classList.contains('collapse');
  }
}
