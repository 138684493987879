
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'footer', 'text', 'form', 'submitBtn' ]

  reset_form(event) {
    this.textTarget.value = ''
    this.submitBtnTarget.disabled = false
  }

  delete_comment(event) {
    const comment_id = event.detail[0]["comment_id"]
    document.getElementById(`comment-${comment_id}`).innerHTML = ''
  }

  show_error(event) {
    // TODO: Error handling!
    this.textTarget.disabled = true
    this.footerTarget.innerHTML += "<br><p class='text-center'><strong>We're sorry, there has been a server-side error.</strong></p>"
  }
}
