
import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let config = {
      plugins: ['remove_button'],
      valueField: 'value',
      maxItems: 1,
      hidePlaceholder: true
    }

    this._tomSelect = new TomSelect(this.element, config)
  }
}
